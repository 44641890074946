import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { flowRight, values } from 'lodash';
import MoreIcon from '../icons/more-icon-new';
import { connect } from '../../../common/components/runtime-context';
import * as ActionSheetTypes from '../modals/action-sheet/action-sheet-modal-types';
import styles from './more-button-mobile.scss';

const SIZE_SMALL = 'small';

const MoreButtonMobile = ({ Icon, className, openModal, type, onClose, size, ...props }) => (
  <div className={className} data-hook="more-button">
    <button
      className={classNames(styles.moreButtonMobile, styles[size])}
      onClick={() => openModal(type, props).then(onClose)}
    >
      <Icon className="forum-icon-fill" />
    </button>
  </div>
);

MoreButtonMobile.SIZE_SMALL = SIZE_SMALL;

MoreButtonMobile.propTypes = {
  className: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  type: PropTypes.oneOf(values(ActionSheetTypes)),
  onClose: PropTypes.func,
  size: PropTypes.oneOf([SIZE_SMALL]),
};

MoreButtonMobile.defaultProps = {
  Icon: MoreIcon,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  openModal: actions.openModal,
});

export default flowRight(connect(mapRuntimeToProps))(MoreButtonMobile);
