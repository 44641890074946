import { withStyles } from 'native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import RecentPostsWidgetRoot from './components/recent-posts-widget-root';
import { withStateReceiver } from '../common/services/state-optimizer/with-state-receiver';
import { withLayoutUpdate } from '../common/hoc/with-layout-update';
import { withInvalidAppState } from '../common/hoc/with-invalid-app-state';
import { withSentryErrorBoundaryRPW } from '../common/hoc/with-error-boundary';
import InvalidAppStatePage from '../app/components/invalid-app-state-page';

const withStylesConfig = {
  cssPath: ['recent-posts-widget-viewer.bundle.css', 'recent-posts-widget-viewer.css'],
};

const FallbackPage = withStyles(InvalidAppStatePage, withStylesConfig);

export default {
  component: withSentryErrorBoundaryRPW(
    withLayoutUpdate(
      withInvalidAppState(withStateReceiver(withStyles(RecentPostsWidgetRoot, withStylesConfig))),
    ),
    FallbackPage,
  ),
};
