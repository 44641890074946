import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import withDirection, { DIRECTIONS, withDirectionPropTypes } from 'react-with-direction';
import { APP_TEXT_COLOR_PATH } from '@wix/communities-forum-client-commons/dist/src/constants/wix-params';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import { css } from 'emotion';
import MoreIcon from '../icons/more-icon';
import styles from './more-button.scss';
import {
  handleArrowDownKeyPressed,
  handleArrowUpKeyPressed,
  handleEscapeKeyUp,
  handleTabKeyUp,
} from '../../services/accessibility';
import withSettingsColor from '../../hoc/with-settings-color';
import { POSITION_REVERSE_MAP } from '../../constants/direction-reverse-map';

const BOTTOM_THRESHOLD = 45;
const SIZE_SMALL = 'small';
const SIZE_EXTENDED = 'extended';

const DIRECTION_PREVIOUS = 'previous';
const DIRECTION_NEXT = 'next';

export class MoreButton extends Component {
  dropdownOpenedWithKeyboard = false;

  constructor(props) {
    super(props);
    this.handleArrowUpKeyPressed = handleArrowUpKeyPressed(
      this.focusSiblingMenuItem(DIRECTION_PREVIOUS),
    );
    this.handleArrowDownKeyPressed = handleArrowDownKeyPressed(
      this.focusSiblingMenuItem(DIRECTION_NEXT),
    );
    this.handleEscapeKeyUp = handleEscapeKeyUp(this.hideComponentWithFocus);
    this.handleTabKeyUp = handleTabKeyUp(this.hideComponentWithFocus);
  }

  state = {
    isVisible: false,
  };

  handleKeyDown = event => {
    this.dropdownOpenedWithKeyboard = event.keyCode === 13 || event.keyCode === 32;
  };

  handleClick = event => {
    if (this.state.isVisible) {
      this.hideComponent();
    } else {
      this.showComponent();
    }
    event.preventDefault();
  };

  setContainer = element => (this.container = element);

  setActionsContainer = element => (this.actionsContainer = element);

  setMoreButton = element => (this.moreButton = element);

  checkIfFits = () => {
    const rect = this.container.getBoundingClientRect();
    const clientHeight = document.documentElement.clientHeight;
    const actionsHeight = this.actionsContainer ? this.actionsContainer.offsetHeight : 0;
    const fitDown = clientHeight - rect.top > actionsHeight + BOTTOM_THRESHOLD;
    const fitUp = !fitDown && actionsHeight !== 0 && rect.top > actionsHeight;
    this.setState({
      isAtTop: fitUp,
    });
  };

  showComponent = () => {
    document.addEventListener('click', this.hideComponent);
    this.setState({ isVisible: true }, this.onIsVisibleSet);
    this.props.onShow();
  };

  onIsVisibleSet = () => {
    this.checkIfFits();
    this.focusFirstMenuItem();
    this.actionsContainer.addEventListener('click', this.focusMoreButton);
  };

  focusFirstMenuItem = () => {
    const menuItem = this.focusMenuItem('[role="menuitem"]');
    //a hack to show the outline on the first element
    if (this.dropdownOpenedWithKeyboard) {
      const event = new window.KeyboardEvent('keyup', { keyCode: 39, bubbles: true });
      menuItem && menuItem.dispatchEvent(event);
      this.dropdownOpenedWithKeyboard = false;
    }
  };
  focusLastMenuItem = () => this.focusMenuItem('[role="menuitem"]:last-child');

  focusMenuItem = selector => {
    if (!this.actionsContainer) return;
    const menuItem = this.actionsContainer.querySelector(selector);
    menuItem && menuItem.focus();
    return menuItem;
  };

  hideComponentWithFocus = event => {
    event.preventDefault();
    this.focusMoreButton();
    this.hideComponent();
  };

  focusMoreButton = () => this.moreButton && this.moreButton.focus();

  hideComponent = ev => {
    if (
      this.props.noCloseOnContainerClick &&
      ev !== undefined &&
      ev.path.includes(this.actionsContainer)
    ) {
      return;
    }

    document.removeEventListener('click', this.hideComponent);
    this.actionsContainer.removeEventListener('click', this.focusMoreButton);
    this.setState({ isVisible: false });
    this.props.onHide();
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.hideComponent);
  };

  renderActionsContainer = () => {
    const {
      contentFontClassName,
      actionsContainerClassName,
      cardBackgroundColor,
      direction,
      position,
      forceLTR,
      dataHook,
    } = this.props;
    const actionsContainerClass = classNames(
      styles.actions,
      contentFontClassName,
      'default-desktop-header-text-color',
      { [styles.actionsTop]: this.state.isAtTop },
      /*
      ordering of css property is important here - this allows to override container background color
      using actionsContainerClassName prop.
      */
      css`
        background-color: ${cardBackgroundColor};
      `,
      actionsContainerClassName,
    );
    const effectivePosition =
      direction === DIRECTIONS.LTR || forceLTR ? position : POSITION_REVERSE_MAP[position];
    const actionsContainerStyle = {
      ...(effectivePosition === 'left' ? { right: 0 } : { left: 0 }),
    };

    return (
      <div
        className={actionsContainerClass}
        style={actionsContainerStyle}
        role="menu"
        ref={this.setActionsContainer}
        data-hook={dataHook}
        onKeyDown={this.handleArrowKeysPressed}
      >
        {this.props.children}
      </div>
    );
  };

  handleArrowKeysPressed = event => {
    this.handleArrowUpKeyPressed(event);
    this.handleArrowDownKeyPressed(event);
  };

  focusSiblingMenuItem = direction => event => {
    event.preventDefault();
    const methodName = `${direction}ElementSibling`;
    let sibling = event.target[methodName];
    while (sibling) {
      if (sibling.getAttribute('role') === 'menuitem') {
        sibling.focus();
        return;
      }
      sibling = sibling[methodName];
    }
    if (direction === DIRECTION_PREVIOUS) this.focusLastMenuItem();
    else this.focusFirstMenuItem();
  };

  handleCloseKeysPressed = event => {
    if (this.state.isVisible) {
      this.handleEscapeKeyUp(event);
      this.handleTabKeyUp(event);
    }
  };

  render = () => {
    const {
      id,
      icon,
      t,
      isWhite,
      className: containerClassName,
      buttonClassName,
      iconClassName,
      size,
      ariaLabel,
      target,
      contentFontClassName,
      skipIconClass,
    } = this.props;
    const className = classNames(containerClassName, styles.more, styles[size], 'more-button', {
      [styles.moreWhite]: isWhite,
    });

    let btnAriaLabel;

    if (ariaLabel) {
      btnAriaLabel = ariaLabel;
    } else if (target) {
      btnAriaLabel = t('more-button.more-actions-for', { target });
    } else {
      btnAriaLabel = t('more-button.more-actions');
    }

    return (
      <div
        className={className}
        ref={this.setContainer}
        onKeyUp={this.handleCloseKeysPressed}
        data-hook="more-button"
      >
        <button
          ref={this.setMoreButton}
          onKeyDown={this.handleKeyDown}
          onClick={this.handleClick}
          className={classNames(
            { [styles.icon]: !skipIconClass },
            'button-hover-fill',
            iconClassName,
            'forum-text-color',
            contentFontClassName,
            buttonClassName,
          )}
          aria-label={btnAriaLabel}
          id={id}
          aria-haspopup="true"
          aria-expanded={this.state.isVisible}
        >
          {icon}
        </button>
        {this.state.isVisible ? this.renderActionsContainer() : null}
      </div>
    );
  };
}

MoreButton.propTypes = {
  cardBackgroundColor: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  cardBorderColor: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  skipIconClass: PropTypes.bool,
  actionsContainerClassName: PropTypes.string,
  isWhite: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.node,
  t: PropTypes.func.isRequired,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  ...withDirectionPropTypes,
  position: PropTypes.oneOf(['left', 'right']),
  size: PropTypes.oneOf([SIZE_SMALL, SIZE_EXTENDED]),
  forceLTR: PropTypes.string,
  ariaLabel: PropTypes.string,
  target: PropTypes.string,
  dataHook: PropTypes.string,
};

MoreButton.defaultProps = {
  icon: <MoreIcon />,
  iconClassName: 'forum-icon-fill',
  skipIconClass: false,
  position: 'left',
  onShow: () => {},
  onHide: () => {},
  dataHook: 'actions',
};

MoreButton.SIZE_SMALL = SIZE_SMALL;
MoreButton.SIZE_EXTENDED = SIZE_EXTENDED;

export default flowRight(
  withSettingsColor({
    path: APP_TEXT_COLOR_PATH,
    propName: 'cardBorderColor',
    alpha: 0.25,
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.25,
    fallbackColor: '#000000',
  }),
  withCardBackgroundColor,
  withTranslate,
  withFontClassName,
  withDirection,
)(MoreButton);
