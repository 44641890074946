import React from 'react';

export const PinHollowIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fill-rule="evenodd"
      d="M7 18.5V6c0-.552.448-1 1-1h8c.552 0 1 .448 1 1v12.5c0 .503-.503.578-.777.416L12 16.101l-4.223 2.815C7.516 19.098 7 19 7 18.5zM8 6v11.566l4-2.667 4 2.667V6H8z"
    />
  </svg>
);
